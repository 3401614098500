import request from '../utils/request';
import { REST_SERVICE } from '../config';

// 查询 我的申请
export const getCheckCertifies = (params) =>
  request({
    url: REST_SERVICE.myQuery.getCheckCertifies,
    method: 'get',
    params
  });

// 查看详情页
export const getCertifiesResult = (params) =>
  request({
    url: REST_SERVICE.myQuery.getCertifiesResult,
    method: 'get',
    params
  });

// 打印权属证明
export const printQszm = (params) =>
  request({
    url: REST_SERVICE.myQuery.printQszm,
    method: 'post',
    params
  });
// 打印权属证明带签章
export const printQszmWithSignature = (params) =>
  request({
    url: REST_SERVICE.myQuery.printQszmWithSignature,
    method: 'post',
    params
  });

// 取消查询权属证明
export const cancelCheckCertifies = (params) =>
  request({
    url: REST_SERVICE.myQuery.cancelCheckCertifies,
    method: 'get',
    params
  });
export const getQszmHtml = (data) =>
  request({
    url: REST_SERVICE.myQuery.getQszmHtml,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  });
export const reviewFwcd = (params, data = []) =>
  request({
    url: REST_SERVICE.myQuery.reviewFwcd,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    params,
    data,
  });
// 打印他人房查
export const printOthers = (data) =>
  request({
    url: REST_SERVICE.myQuery.printOthers,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  });
// 打印权属证明银行
export const printQszmyh = (data) =>
  request({
    url: REST_SERVICE.myQuery.printQszmyh,
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  });
// 打印抵押合同
export const printDyht = (params) =>
  request({
    url: REST_SERVICE.myQuery.printDyht,
    method: 'post',
    params
  });
// 打印有房权属证明
export const printYfqszm = (data) =>
  request({
    url: REST_SERVICE.myQuery.printYfqszm,
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  });
// 打印无房权属证明
export const printWfqszm = (data) =>
  request({
    url: REST_SERVICE.myQuery.printWfqszm,
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  });

// 根据业务号或取房产查询附件信息
export const getFjclByYwh = (params) =>
  request({
    url: REST_SERVICE.myQuery.getFjclByYwh,
    method: 'post',
    params
  });
// 打印缴费单
export const printQlxxPay = (params) =>
  request({
    url: REST_SERVICE.myQuery.printQlxxPay,
    method: 'post',
    params
  });
// 打印权属证明
export const printQszmXml = (params) =>
  request({
    url: REST_SERVICE.myQuery.printQszmXml,
    method: 'post',
    params,
  });