<template>
  <div>
    <el-dialog
      title="证书证明结果"
      :visible.sync="dialogVisible"
      width="70%"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="form" label-width="100px">
        <div class="content-detail">
          <el-form-item label="查询编号：">
            <el-input v-model="form.cxbh" disabled></el-input>
          </el-form-item>
          <el-form-item label="查询结果：">
            <el-input v-model="form.cxjg" disabled></el-input>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer flex-center">
        <el-button @click="handleClose" :loading="loading">关 闭</el-button>
        <!--<el-button type="primary" @click="dialogVisible = false">确 定</el-button>-->
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {getCertifiesResult} from '@iebdc/api/my-query.js';

export default {
  data() {
    return {
      dialogVisible: false,
      form: {
        cxbh: '',
        cxjg: ''
      },
      loading: false
    };
  },
  methods: {
    open(row) {
      console.log('detailrow--->>>', row);
      this.dialogVisible = true;
      this.form.cxbh = row.cxbh;
      this.getDetailInfo();
    },
    getDetailInfo() {
      this.loading = true;
      getCertifiesResult({cdbh: this.form.cxbh})
        .then((res) => {
          // console.log('getCertifiesResult--->>>', res);
          if (res.success) {
            // switch (res.data.result) {
            //   // case '0':
            //   //   this.form.cxjg = '暂无此证书/证明相关信息,请确认填写信息是否正确';
            //   //   break;
            //   default:
            //     this.form.cxjg = '暂无此证书/证明相关信息,请确认填写信息是否正确';
            //     break;
            // }
            this.form.cxjg = res.data.result;
          } else {
            this.form.cxjg = '暂无此证书/证明相关信息,请确认填写信息是否正确';
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleClose() {
      for (let key in this.form) {
        this.form[key] = '';
      }
      this.dialogVisible = false;
      // this.$confirm('确认关闭？')
      //   .then(_ => {
      //     done();
      //   })
      //   .catch(_ => {
      //   });
    }
  }
};
</script>

<style lang="scss" scoped>
.content-detail {
  display: flex;
  justify-content: space-around;
}

.el-form-item--small {
  width: 100%;
}
</style>
