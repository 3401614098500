<template>
  <div class="full-height">
    <e-search
      style="padding: 8px 0; height: 70px"
      :filterGroup="filterGroup"
      :btnsConfig="btnsConfig"
      @search="search"
      @reset="reset"
    ></e-search>
    <e-table
      ref="myApplicationRef"
      :tableList="tableList"
      :columns="columns"
      :operates="operates"
      :options="options"
      @afterCurrentPageClick="afterCurrentPageClickHandle"
    ></e-table>
    <!--查看详情-->
    <look-over-detail ref="lookOverDetail" />
  </div>
</template>

<script>
import ESearch from '@/components/e-search';
import ETable from '@/components/e-table';
import { getCheckCertifies, printQszm, printQszmWithSignature, cancelCheckCertifies } from '@iebdc/api/my-query.js';
import { IEBDC_USER_DATA } from '@iebdc/config/app-data';
import utils from '@/utils/utils';
import LookOverDetail from '../model/look-over-detail';

export default {
  name: 'myQuery',
  data() {
    return {
      btnsConfig: {
        clearBtnText: '清除',
        showResetBtn: true,
        showMoreBtn: false,
      },
      dropdownList: [
        {
          label: '打印申请表',
          dropdownItemclick: () => {
            console.log('打印申请表');
          },
        },
        {
          label: '打印受理凭证',
          dropdownItemclick: () => {
            console.log('打印受理凭证');
          },
        },
        {
          label: '打印抵押证明',
          dropdownItemclick: () => {
            console.log('打印抵押证明');
          },
        },
        {
          label: '打印缴费通知单',
          dropdownItemclick: () => {
            console.log('打印缴费通知单');
          },
        },
      ],
      filterGroup: [
        {
          label: '查询类型',
          type: 'dicSelect',
          dicType: '查询类型',
          placeholder: '查询类型',
          key: 'checkCertifyTypeEnum',
          value: '',
        },
        {
          label: '查询编号',
          key: 'cxbh',
          type: 'input',
          placeholder: '查询编号',
          value: '',
        },
      ],
      tableList: [],
      columns: [
        {
          width: '160px',
          // prop: 'cdcode',
          prop: 'cxbh',
          label: '查档编号',
        },
        {
          width: '120px',
          prop: 'checkCertifyTypeEnum',
          label: '查询类型',
          type: 'dic',
        },
        {
          width: '180px',
          prop: 'sqr',
          label: '申请人',
        },
        {
          width: '180px',
          // prop: 'zjCode',
          prop: 'zjh',
          label: '证件号',
        },
        {
          width: '140px',
          // prop: 'sqsj',
          prop: 'ctime',
          label: '查询时间',
          type: 'date',
          formatter: (scope, columns, data) => {
            return utils.dateFtt(data, 'yyyy-MM-dd');
          },
        },
        {
          // prop: 'cxStatus',
          prop: 'checkCertifyStatusEnum',
          width: 160,
          label: '查询状态',
          formatter: (scope, columns, data) => {
            // console.log(scope,'scope',columns,'colums',data,'data---');
            let html = '';
            switch (data) {
              case 'CXWC':
                html += '<span>查询完成</span>';
                break;
              case 'QXCX':
                html += '<span style="color: #ccc">取消查询</span>';
                break;
              case 'CXSB':
                html += '<span>查询失败</span>';
                break;
              case 'SHWTG':
                html += '<span>审核未通过</span>';
                break;
              case 'SHZ':
                html += '<span>审核中</span>';
                break;
              default:
                '';
            }
            return html;
          },
        },
      ],
      operates: {
        //表格操作栏
        width: '140px',
        fixed: 'right',
        list: [
          // {
          //   show: true,
          //   disabled: (row) => {
          //     if (row.step === 'NETAPPLYING') {
          //       return true;
          //     }
          //   },
          //   spanType: 'button',
          //   id: '1',
          //   label: '查看',
          //   type: 'success',
          //   method: (key, row) => {
          //     // console.log(row);
          //     this.$refs.lookOverDetail.open(row);
          //   },
          //   showCallback: (row) => {
          //     // console.log(row,'row---')
          //     return true
          //     // return row.checkCertifyTypeEnum === 'ZSZMHS';
          //   }
          // },

          {
            show: true,
            disabled: (row) => {
              if (row.checkCertifyStatusEnum !== 'CXWC') {
                return true;
              } else {
                return false;
              }
            },
            spanType: 'button',
            id: '1',
            label: '下载',
            type: 'text',
            size: 'medium',
            color: 'success',
            method: (key, row) => {
              printQszmWithSignature({ cxbh: row.cxbh }).then((res) => {
                // 202008191735141196
                if (res.data && res.success) {
                  window.open(res.data);
                } else {
                  this.$message.warning('下载失败!');
                }
              });
            },
            showCallback: () => {
              return true;
            },
          },
          {
            show: true,
            disabled: (row) => {
              if (row.checkCertifyStatusEnum === 'QXCX' || row.checkCertifyStatusEnum === 'CXWC') {
                return true;
              } else {
                return false;
              }
            },
            spanType: 'button',
            id: '2',
            label: '取消',
            type: 'text',
            size: 'medium',
            color: 'primary',
            method: (key, row) => {
              cancelCheckCertifies({ cxbh: row.cxbh }).then((res) => {
                if (res.success) {
                  let params = {
                    nCurrent: this.currentPage,
                    loginName: IEBDC_USER_DATA().loginName,
                  };
                  this.query({ ...params, ...this.queryParams });
                }
              });
            },
            showCallback: () => {
              return true;
            },
          },
          {
            show: true,
            spanType: 'button',
            id: '3',
            type: 'text',
            size: 'medium',
            color: 'primary',
            label: '查看失败原因',
            // type: 'danger',
            method: (key, row) => {
              this.$alert(row.reviewOpinion, '失败原因', {
                confirmButtonText: '确定',
                callback: (action) => {},
              });
            },
            showCallback: (row) => {
              if (row.checkCertifyStatusEnum === 'SHWTG') {
                return true;
              } else {
                return false;
              }
            },
          },
        ],
      },
      options: {
        // 每页数据数
        pageSize: 10,
        // 当前页码
        currentPage: 1,
        loading: true,
        mutiSelect: false,
        hasIndex: false,
        maxHeight: '5000px',
        width: '300px',
      },
      currentPage: 0, // 存起来的当前页码,给取消操作时,用来刷新查询当前页
      queryParams: {}, // 存起来的当前查询参数,给取消操作时,用来刷新查询当前页
    };
  },
  components: {
    ESearch,
    ETable,
    LookOverDetail,
  },
  methods: {
    // 初始化数据
    init() {
      let params = {
        nCurrent: 0,
        loginName: IEBDC_USER_DATA().loginName,
      };
      this.query(params);
    },
    query(params) {
      getCheckCertifies(params).then((res) => {
        if (res.success && res.data) {
          if (res.data.page) {
            //当接口返回数据的时候
            if (res.data.page.length > 0) {
              let copyData = [...res.data.page];
              copyData.forEach((item) => {
                if (item.familyMemberList != null) {
                  item.sqr = item.familyMemberList[0].name;
                  item.zjh = item.familyMemberList[0].zjh;
                }
              });
              this.tableList = copyData;
            } else {
              //当获取的数据为空的时候
              this.tableList = res.data.page;
            }
            // this.$refs.myApplicationRef.setTableList(res.data.page);
          }
          this.$refs.myApplicationRef.setTotal(res.data.recordCount);
          this.$refs.myApplicationRef.setPageSize(res.data.pageSize);
        } else {
          this.$refs.myApplicationRef.setTableList([]);
          this.$refs.myApplicationRef.setTotal(0);
          this.$message.warning(res.message);
        }
      });
    },
    // 查询
    search(queryParams) {
      this.queryParams = { ...queryParams }; // 存起来的当前查询参数,给取消操作时,用来刷新查询当前页
      let params = {
        nCurrent: 0,
        loginName: IEBDC_USER_DATA().loginName,
      };
      let searchParams = Object.assign(params, queryParams);
      this.query(searchParams);
      this.$refs.myApplicationRef.setCurrentPage(1);
    },
    // 清除(重置)
    reset() {
      this.init();
      this.$refs.myApplicationRef.setCurrentPage(1);
    },
    // 翻页
    afterCurrentPageClickHandle(val) {
      this.currentPage = val - 1; // 存起来的当前页码,给取消操作时,用来刷新查询当前页
      let params = {
        nCurrent: 0,
        loginName: IEBDC_USER_DATA().loginName,
      };
      params.nCurrent = val - 1;
      this.query(params);
    },
    // 删除数组指定元素返回新数组且不改变原数组
    remove(arr, item) {
      let result = [];
      arr.forEach((element) => {
        if (element !== item) {
          result.push(element);
        }
      });
      return result;
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.content {
  margin-bottom: 20px;
  padding: 25px 25px 20px 25px;
  width: 100%;
  /*min-height: 555px;*/
  height: auto;
  background: #ffffff;

  .bread-menu-wrap {
    line-height: 40px;
    font-size: 13px;

    .bread-menu-wrap-right {
      span {
        color: #145ccd;
        cursor: pointer;
      }
    }
  }

  .table-wrap {
    .top-tab {
      height: 42px;
      border-bottom: 1px solid #e7e7e7;

      .tab-text {
        padding: 0px 20px;
        line-height: 42px;
        border-bottom: #0168b7 solid 1.5px;
        color: #0168b7;
        font-size: 16px;
      }
    }

    .table-filter {
      margin: 20px 0;

      /deep/ .flex-column {
        .left-panel {
          .filter-item:nth-child(1) {
            .el-select:nth-child(1) {
              width: 100px !important;
            }
          }
        }
      }
    }
  }
}

/deep/ .e-table {
  position: relative !important;
  .el-table__body-wrapper {
    height: auto !important;
  }
}
</style>
